import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ModalCloseOptions } from 'constants/modalCloseOptions';

import DeleteButton from 'components/ShoppingList/DeleteButton/Button';
import DeleteButtonModal from 'components/ShoppingList/DeleteButton/Modal';

import styles from './ShoppingListDeleteButton.scss';

const ShoppingListDeleteButton = ({ deleteShoppingList, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const deleteButtonProps = {
    title: 'Delete shopping list',
    action: () => setIsModalOpen(true),
  };
  const deleteModalProps = {
    buttons: [
      {
        buttonText: 'Remove',
        buttonValue: ModalCloseOptions.OK,
      },
      {
        buttonText: 'Cancel',
        buttonValue: ModalCloseOptions.CANCEL,
      },
    ],
    closeModal: value => {
      setIsModalOpen(false);
      if (value === ModalCloseOptions.OK) {
        deleteShoppingList();
      }
    },
    modalMessage: 'Are you sure you want to remove this list?',
    isOpen: isModalOpen,
  };

  return (
    <>
      <DeleteButton {...deleteButtonProps} />
      <DeleteButtonModal {...deleteModalProps}>
        {name && (
          <p className={styles.listName} data-cs-mask>
            {name}
          </p>
        )}
      </DeleteButtonModal>
    </>
  );
};

ShoppingListDeleteButton.displayName = 'ShoppingListDeleteButton';

ShoppingListDeleteButton.propTypes = {
  deleteShoppingList: PropTypes.func.isRequired,
  name: PropTypes.string, // eslint-disable-line react/require-default-props
};

export default ShoppingListDeleteButton;
