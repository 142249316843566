import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import classNames from 'classnames';
import DeleteButton from 'components/ShoppingList/DeleteButton';

import { concatenateTitle } from 'utils/concatenate-title-object';

import EditButton from 'components/PageTitle/EditButton';
import { HeartActive } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './PageTitle.scss';

/**
 * @deprecated
 * **USE THE `PageTitle` COMPONENT FROM THE INGREDIENTS PACKAGE**
 *
 * @example
 * import { PageTitle } from '@johnlewispartnership/wtr-ingredients/ingredients/PageTitle';
 */
const PageTitle = pure(
  ({
    capitalise,
    dataWebviewid,
    display,
    editable = false,
    deletable = false,
    editAction,
    EditForm,
    editing = false,
    hasSubTitle,
    highlight,
    icon,
    invert,
    left,
    children,
    masked,
    name = 'page-title',
    noMargin = false,
    offersHighlight,
    suffix,
    text,
    underline,
    uppercase = true,
    id,
    listId,
  }) => {
    if (
      !children &&
      (!display || concatenateTitle({ highlight, offersHighlight, suffix, text }).length < 1)
    )
      return null;

    const classes = classNames(styles.title, {
      [styles.capitalise]: capitalise,
      [`${styles.hidden} sr-only`]: editing,
      [styles.hasSubTitle]: hasSubTitle,
      [styles.invert]: invert,
      [styles.underline]: underline,
      [styles.uppercase]: uppercase,
      [styles[`${icon}Icon`]]: icon,
      [styles.h1noMargin]: noMargin,
    });

    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.editable]: editable,
          [styles.left]: left,
          [styles.noMargin]: noMargin,
        })}
        data-test="page-title-wrapper"
      >
        <h1
          className={classes}
          data-testid={name}
          data-webviewid={dataWebviewid}
          id={id}
          data-cs-mask={masked || undefined}
        >
          {icon === 'favourites' && (
            <HeartActive name="HeartActive" size="large" className={styles.favourites} />
          )}
          {children}
          {text}
          {highlight && <span className={styles.highlight}>{highlight}</span>}
          {offersHighlight && <span className={styles.offerHighlight}>{offersHighlight}</span>}
          {suffix}
        </h1>
        {editing && (
          <div className={styles.editFormWrapper}>
            <EditForm />
          </div>
        )}
        {editable && !editing && (
          <div className={deletable ? styles.editButtonCentered : styles.editButton}>
            <EditButton onClick={editAction} />
          </div>
        )}
        {deletable && (
          <div className={styles.deleteButton}>
            <DeleteButton listId={listId} redirect />
          </div>
        )}
      </div>
    );
  },
);

PageTitle.propTypes = {
  id: PropTypes.string,
  capitalise: PropTypes.bool,
  dataWebviewid: PropTypes.string,
  display: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  editAction: PropTypes.func,
  EditForm: PropTypes.func,
  editing: PropTypes.bool,
  hasSubTitle: PropTypes.bool,
  highlight: PropTypes.string,
  icon: PropTypes.oneOf(['favourites']),
  invert: PropTypes.bool,
  left: PropTypes.bool,
  children: PropTypes.node,
  masked: PropTypes.bool,
  mywaitroseSuffix: PropTypes.string,
  noMargin: PropTypes.bool,
  offersHighlight: PropTypes.string,
  suffix: PropTypes.string,
  text: PropTypes.string,
  underline: PropTypes.bool,
  uppercase: PropTypes.bool,
  listId: PropTypes.string,
};

PageTitle.displayName = 'PageTitle';

export default PageTitle;
