import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

import modalButton from 'constants/data-shapes/modal-button';
import { ModalCloseOptions } from 'constants/modalCloseOptions';

import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';

const DeleteButtonModal = pure(({ buttons, children, closeModal, isOpen, modalMessage }) => (
  <Modal
    buttons={buttons}
    closeModal={closeModal}
    isOpen={isOpen}
    titleText={modalMessage}
    dataTest="delete-modal"
  >
    {children}
  </Modal>
));

DeleteButtonModal.displayName = 'DeleteButtonModal';

DeleteButtonModal.propTypes = {
  buttons: PropTypes.arrayOf(modalButton()),
  children: PropTypes.node,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
};

DeleteButtonModal.defaultProps = {
  buttons: [
    {
      buttonText: 'Delete',
      buttonValue: ModalCloseOptions.OK,
    },
    {
      buttonText: 'Cancel',
      buttonValue: ModalCloseOptions.CANCEL,
    },
  ],
  closeModal: () => {},
  isOpen: false,
  modalMessage: 'Delete',
};

export default DeleteButtonModal;
